.privacy-policy-container {
  padding: 20px;
  background-color: #ffffff;
  color: #333;
  font-family: Arial, sans-serif;
  border: 1px solid #ddd;
  border-radius: 8px;
  max-width: 800px;
  margin: 50px auto;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  position: relative;
}

.privacy-policy-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.privacy-policy-header h1 {
  margin: 0;
  font-size: 24px;
  font-weight: bold;
  color: #000;
}

.close-button {
  background-color: #ff4d4f;
  color: white;
  border: none;
  border-radius: 4px;
  font-size: 16px;
  padding: 5px 10px;
  cursor: pointer;
}

.close-button:hover {
  background-color: #ff7875;
}

.privacy-policy-content {
  line-height: 1.6;
  text-align: left; /* Align all text to the left */
}

.privacy-policy-content h2 {
  color: #0056b3;
  margin-top: 20px;
  font-size: 20px;
  text-align: left; /* Align headings to the left */
}

.privacy-policy-content p,
.privacy-policy-content ul {
  margin: 10px 0;
  text-align: left; /* Align paragraphs and lists to the left */
}

.privacy-policy-content ul {
  padding-left: 20px;
}

.privacy-policy-content li {
  margin-bottom: 8px;
}
