/* General Reset */
@import url('https://fonts.googleapis.com/css2?family=Kantumruy+Pro:wght@400;600&family=Manrope:wght@400;600&family=Poppins:wght@400;600&family=Roboto:wght@400;600&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Lato:wght@400;700&display=swap');
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  scroll-behavior: smooth;
  width: 100%; /* Prevents horizontal scroll */
  overflow-x: hidden; /* Hides horizontal scrollbar */
}

body {
  font-family: Arial, sans-serif;
  background-color: #f4f7fa;
  color: #333;
  overflow-x: hidden; /* Prevents horizontal scroll */
}

.App {
  text-align: center;
  width: 100%; /* Ensures the app takes full width */
  overflow-x: hidden; /* Hides horizontal scrollbar */
}

/* Navbar */
.navbar {
  background-color: #0C80EB; /* Blue background */
  padding: 20px 20px;
  /* position: fixed; */
   /* Makes the navbar fixed while scrolling */
  top: 0; /* Stays at the top of the page */
  width: 100%; /* Ensures it spans the full width */
  z-index: 1000; /* Ensures it stays above other elements */
}

.navbar-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.nav-links a {
  text-decoration: none;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 141.77%;
  color: #FFFFFF;
}

.logo-container {
  display: flex;
  align-items: center;
  margin-left: 60px;
}

.logo-container-mobile {
  display: flex;
  align-items: center;
  margin-left: 5px;
}

.logo {
  width: 180px;
  height: 55px;
}

.menu-icon {
  background: none;
  border: none;
  color: white;
  font-size: 38px; /* Adjust size as needed */
  cursor: pointer;
  margin-right: 10px;
}

.get-started-Demo-btn {
  width: 224px;
  height: 44px;
  background-color: transparent;
  padding: 10px 20px;
  border: 2px solid;
  border-radius: 0px;
  border-color: #FFFFFF;
  cursor: pointer;
  transition: background-color 0.3s ease;
  font-family: 'Lato';
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 141.77%;
  /* identical to box height, or 26px */

  color: #FFFFFF;
  margin-right: 60px;
}

.get-started-btn:hover {
  background-color: white;
  color: #007bff;
}

.get-started-btn-mobile {
  background-color: white;
  border: 2px solid white;
  color: #0069d9;
  padding: 16px 33px;
  border-radius: 5px;
  font-size: large;
  font-weight: bold;
  cursor: pointer;
  margin-bottom: 30px;
}

.get-started-btn-mobile:hover {
  background-color: white;
  color: #007bff;
}

/* Menu Dashboard */
.menu-dashboard {
  position: absolute;
  top: 80px; /* Adjust according to navbar height */
  left: 0;
  width: 100%;
  height: 45%;
  background-color: white; /* Same color as navbar */
  padding: 10px 0;
  z-index: 1000; /* Ensures it appears above other elements */
}

.menu-dashboard .nav-links-mobile {
  display: flex;
  flex-direction: column; /* Stack links vertically */
  padding: 10px;
  gap: 10px
}

.menu-dashboard .nav-links-mobile li {
  list-style: none;
  padding: 10px 20px; /* Adjust padding for links */
}

.menu-dashboard .nav-links-mobile a {
  color: #007bff;
  font-size: 20px;
  font-weight: 700;
  font-family: 'Times New Roman', Times, serif;
  text-decoration: none; /* Remove underline from links */
  display: block; /* Makes the whole area clickable */
  text-align: left; 

}

.menu-dashboard .nav-links a:hover {
  background-color: rgba(255, 255, 255, 0.2); /* Slight highlight on hover */
}

.nav-links {
  display: flex;
  gap: 20px;
}

.nav-links li {
  list-style: none;
  color: white;
  cursor: pointer;
}

.nav-links a {
  list-style: none;
  color: white;
  cursor: pointer;
}

/* Hero Section */
.hero-section {
  background-image: "./Home_Page_Background.png";
  background-size: cover; /* Make sure the image covers the entire section */
  background-position: center; /* Center the background image */
  background-repeat: no-repeat; /* Prevent the background from repeating */
  color: white;
  padding: 180px 20px;
  justify-content: top;
}

.hero-title {
  margin-top: 0px;
  font-size: 2.5rem;
  margin-bottom: 40px;
  font-family: 'Kantumruy Pro';
  font-style: normal;
  font-weight: 600;
  font-size: 58px;
  line-height: 141.77%;
  /* or 82px */
  text-align: center;

  color: #FFFFFF;

}

.hero-description {
  font-size: 1.2rem;
  margin-bottom: 40px;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 39px;
/* or 195% */
  text-align: center;

  color: #FFFFFF;
}

.hero-buttons {
  display: flex;
  justify-content: center;
  gap: 20px;
}

.request-demo-btn,
.contact-btn {
  background-color: white;
  color: #007bff;
  border: none;
  padding: 15px 30px;
  font-weight: bold;
  border-radius: 5px;
  cursor: pointer;
}

.request-demo-btn:hover,
.contact-btn:hover {
  background-color: #0069d9;
  color: white;
}

@media only screen and (max-width: 768px) {

  .hero-section {
    background-image: "./Home_Page_Background.png";
    background-size: cover; /* Make sure the image covers the entire section */
    background-position: center; /* Center the background image */
    background-repeat: no-repeat; /* Prevent the background from repeating */
    color: white;
    padding: 110px 20px;
    justify-content: top;
  }

  .hero-title {
    margin-top: 0px;
    margin-bottom: 40px;
    font-family: 'Kantumruy Pro';
    font-style: normal;
    font-weight: 600;
    font-size: 43px;
    line-height: 121.77%;
    /* or 82px */
    text-align: center;
  
    color: #FFFFFF;
  
  }
  
  .hero-description {
    margin-bottom: 40px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 17px;
    line-height: 39px;
  /* or 195% */
    text-align: center;
  
    color: #FFFFFF;
  }
}

/* About Section */
.about-section {
  padding: 80px 20px;
  background-color: #f8f9fa;
  width: 100%; /* Ensure full width for mobile view */
}

.about-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 1200px;
  margin: 0 auto;
}

.about-images {
  position: relative;
  margin-left: 50px;
}

.about-man-mobile {
  width: 250px;
  height: auto;
  position: absolute;
  left: 50px;
  top: 0;
}

.about-car-image {
  width: 300px;
  height: auto;
  margin-top: 150px;
  margin-left: 180px;
}

.about-star-image {
  position: absolute;
  width: 50px;
  height: auto;
  top: 30px;
  left: 340px;
}

.about-text-container {
  max-width: 450px;
  text-align: left;
  margin-top: 1px;
  margin-right: 80px;
  margin-left: 40px;
  top: 0px;
  display: table-column;
  justify-content: space-between;
  margin: 0 auto;
}

/* Headings & Descriptions */
.about-heading {
  margin-top: 0px;
  color: #007bff;
  font-size: 2rem;
  font-weight: bold;
  margin-bottom: 20px;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 700;
  font-size: 22px;
  line-height: 141.77%;
  color: #0C80EB;
}

.about-title {
  color: #060708;
  font-size: 2rem;
  font-weight: bold;
  margin-bottom: 20px;
  font-family: 'Kantumruy Pro';
  font-style: normal;
  font-weight: 700;
  font-size: 36px;
  line-height: 141.77%;

  color: #000000;

}

.about-description {
  font-size: 1.2rem;
  margin-bottom: 20px;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;

  color: #475569;

}

.about-subdescription {
  font-size: 1rem;
  color: #0b0303;
  font-weight: bold;
  margin-bottom: 35px;

  font-family: 'Kantumruy Pro';
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 141.77%;
}

/* Mobile View: Stack the images container and text container */
@media only screen and (max-width: 768px) {
  .about-container {
    flex-direction: column; /* Stack items vertically */
    align-items: center; /* Center content on mobile */
    text-align: center; /* Align text to center for better mobile view */
  }

  .about-images {
    position: relative;
    margin-left: 10px;
  }
  
  .about-man-mobile {
    width: 55%;
    height: auto;
    position: absolute;
    left: 1px;
    top: 0;
  }
  
  .about-car-image {
    width: 70%;
    height: auto;
    margin-top: 30%;
    margin-left: 30%;
  }
  
  .about-star-image {
    position: absolute;
    width: 12%;
    height: auto;
    top: 30px;
    left: 65%;
  }

  .about-heading {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 700;
    font-size: 2.1rem;
    line-height: 141.77%;
    color: #0C80EB;
    margin-bottom: 40px;
  }

  .about-text {
    margin-top: 40px; /* Add margin to separate text from images */
    max-width: 100%; /* Full width text for mobile */
    padding: 0 20px; /* Add padding for better readability */
  }
  .about-title {
    color: #060708;
    font-size: 2.3rem;
    font-weight: bold;
    margin-bottom: 35px;
  }
  
  .about-description {
    font-size: 1.1rem;
    margin-bottom: 35px;
  }
  
  .about-subdescription {
    font-size: 1.3rem;
    color: #0b0303;
    font-weight: bold;
    margin-bottom: 35px;
  }
}


/* Features Section */
.features-section {
  padding: 80px 20px;
  background-color: #E6FFFE;
  width: 100%; /* Ensure the section takes full width */
}

.features-layout {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  max-width: 1200px;
  margin: 0 auto;
  width: 100%; /* Ensure the layout takes full width */
}

/* Left side for text */
.features-text {
  width: 25%;
  text-align: left;
  padding-right: 20px;
}

.features-heading {
  margin-bottom: 40px;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 700;
  font-size: 22px;
  line-height: 141.77%;
  color: #0C80EB;
}

.features-title {
  margin-bottom: 20px;

  font-family: 'Kantumruy Pro';
  font-style: normal;
  font-weight: 700;
  font-size: 36px;
  line-height: 141.77%;
  color: #000000;
}

.features-description {
  margin-bottom: 40px;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 141.77%;
  color: #000000;

}

.features-get-started-btn {
  background-color: #007bff;
  /* identical to box height, or 30px */
  width: 144px;
  height: 51px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  width: 144.61px;
  height: 56.42px; 
  font-family: 'Lato';
  font-style: normal;
  font-weight: 700;
  font-size: 21.1558px;
  line-height: 141.77%;
  color: #FFFFFF;

}

/* Right side for feature grid */
.features-grid {
  display: flex;
  flex-wrap: wrap;
  width: 90%;
  gap: 20px;
  justify-content: center;
}

.feature-box {
  background-color: white;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 30px;
  text-align: center;
  width: 270px;
  height: 270px;
  transition: transform 0.3s ease;
  border-radius: 0;
  display: flex;                /* Make the box a flex container */
  flex-direction: column;        /* Arrange children in a column */
  justify-content: space-between;/* Space elements evenly, pushing text to bottom */
  align-items: center;           /* Center items horizontally */
}

.feature-box img {
  width: 68px;
  height: 68px;
}

.feature-box h3 {
  /* Real-Time Parking Updates */
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 141.77%;
  text-align: center;
  color: #000000;
  margin-bottom: 10px;
}

.feature-box p {
  width: 255px;
  height: 96px;
  color: #333;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 23px;
  text-align: center;

  color: #475569;
  align-items: center;
  position: relative;

}

.feature-box:hover {
  transform: translateY(-10px);
}

.features-grid-mobile {
  display: flex;
  flex-wrap: wrap;
  width: 90%;
  gap: 20px;
  justify-content: center;
}

.feature-box-mobile {
  background-color: transparent;
  border-radius: 10px;
  padding: 30px;
  text-align: left;               /* Align text to the left */
  width: 400px;
  height: 190px;
  transition: transform 0.3s ease;
  border-radius: 0;
  display: flex;
  flex-direction: column;         /* Keep items in a column */
  justify-content: flex-start;    /* Align items to the top */
}

.feature-box-header {
  display: flex;
  align-items: center;            /* Vertically center the image and heading */
}

.feature-box-header img {
  width: 68px;
  height: 68px;
  margin-right: 10px;             /* Add some space between the image and heading */
}

.feature-box-header h3 {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 141.77%;
  color: #000000;
}

.feature-box-mobile p {
  color: #475569;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 23px;
  text-align: left;               /* Align paragraph text to the left */
  margin-top: 10px;
  margin-left: 30px;
  margin-right: 30px;
}


/* Mobile styles */
@media only screen and (max-width: 768px) {
  .features-section {
    padding: 80px 20px;
    background-color: #FFFFFF;
    width: 100%; /* Ensure the section takes full width */
  }

  .features-layout {
    flex-direction: column; /* Stack content vertically */
    align-items: center; /* Center content on mobile */
    width: 100%; /* Full width */
  }

  .features-text {
    width: 100%; /* Take full width on mobile */
    align-items: center;
    gap: 20px;
  }

  .features-heading {
    font-size: 2.1rem;
    color: #007bff;
    margin-bottom: 40px;
  }
  
  .features-title {
    font-size: 2.5rem;
    color: #000000;
    margin-bottom: 20px;
    text-align: center;
  }
  
  .features-description {
    font-size: 1.2rem;
    margin-bottom: 40px;
  }

  .features-get-started-btn {
    background-color: #007bff;
    color: white;
    padding: 15px 45px;
    border: none;
    font-size: 20px;
    font-weight: bold;
    border-radius: 5px;
    cursor: pointer;
    margin-bottom: 10px;
    align-items: center;
  }

  .features-grid {
    display: flex;
    flex-wrap: wrap;
    width: 75%;
    gap: 10px;
    justify-content: center;
  }

  .feature-box {
    background-color: white;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    padding: 20px;
    text-align: center;
    width: 294px;
    height: 294px;
    transition: transform 0.3s ease;
    
  }

  .features-title {
    font-size: 2.2rem;
    color: #000000;
    margin-bottom: 20px;
  }
  
  .features-description {
    font-size: 1.0rem;
    margin-bottom: 40px;
  }

  .feature-box h3 {
    font-size: 1.3rem;
    color: #000000;
    margin-bottom: 10px;
  }
  
  .feature-box p {
    font-size: 0.9rem;
    color: #333;
  }
}

/* How It Works Section */
.how-it-works-section {
  padding: 80px 20px;
  background-color: white;
  text-align: center;
}

.how-it-works-heading {
  margin-bottom: 10px;
  position: relative;
  display: inline-block;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 700;
  font-size: 22px;
  line-height: 141.77%;
  /* or 31px */

  color: #0C80EB;
}

.how-valet-works-heading {
  color: #0b0b0b;
  font-size: 2.5rem;
  margin-bottom: 40px;
  position: relative;
  display: inline-block;
  font-family: 'Kantumruy Pro';
  font-style: normal;
  font-weight: 700;
  font-size: 36px;
  line-height: 141.77%;
  /* identical to box height, or 51px */
  text-align: center;

  color: #000000;

}

.how-it-works-steps-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.how-it-works-steps-row {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
}

.step {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 200px;
  text-align: center;
}

.step img {
  width: 126px;
  margin-bottom: 20px;
}

.step-4 img {
  margin-top: 20px;
  width: 95px;
  margin-bottom: 50px;
}

.step h3 {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 141.77%;
  /* identical to box height, or 26px */
  text-align: center;

  color: #000000;
}

.step-4 h3 {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 141.77%;
  /* identical to box height, or 26px */
  text-align: center;

  color: #000000;
}

.step p{

  /* width: 304px;
  height: 48px; */

  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: #475569;

}

.step-4 p{

  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: #475569;

}

.share-otp-image {
  position: absolute;
  top: 0px;    /* Adjust this value to move it vertically */
  left: 50px;   /* Adjust this value to move it horizontally */
  width: 50px; /* Adjust width if needed */
  z-index: 2;  /* Ensures it is on top of the other image */
}

.car-step-image {
  position: relative;
  top: 30px;
  z-index: 1;  /* Keeps the car image behind the OTP image */
  width: 110px;  /* Adjust if needed */
}

.arrow {
  width: 50px;
  margin: 0 20px;
}

/* Mobile Styles */
@media (max-width: 768px) {

  .how-it-works-heading {
    font-size: 2.0rem;
    margin-bottom: 22px;
  }


  .how-it-works-steps-row {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px;
  }

  .step {
    width: 100%;
  }

  .arrow {
    width: 50px;
    transform: rotate(90deg);
    margin: 20px 0;
  }

  .step h3 {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 141.77%;
    /* identical to box height, or 26px */
    text-align: center;
    color: #000000;
    margin-bottom: 10px;
  }
  .step p{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    color: #475569;
    margin-bottom: 40px;
  }

  .step-4 p{

    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    color: #475569;
    margin-bottom: 30px;
  }

}


/* Demo Request Section */
.demo-request-section {
  background-color: #E6FFFE; /* Background color as seen in the image */
  padding: 80px 0;
}

.demo-request-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 1200px;
  margin: 0 auto;
  padding: 40px;
  background-color: #E6FFFE;
  border-radius: 10px;
  box-shadow: 0 10px 20px #E6FFFE(0, 0, 0, 0.1);
}

.demo-image-container {
  flex: 1;
  padding-right: 40px;
}

.demo-image-container img {
  max-width: 100%;
  height: auto;
}

.demo-form-container {
  flex: 1;
  padding-left: 40px;
  align-items: left;
}

.demo-heading {
  margin-bottom: 25px;
  text-align: left;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 700;
  font-size: 22px;
  line-height: 141.77%;
  color: #0C80EB;
}

.demo-subheading {
  margin-bottom: 45px;
  text-align: left;
  font-family: 'Kantumruy Pro';
  font-style: normal;
  font-weight: 700;
  font-size: 36px;
  line-height: 141.77%;
  color: #000000;
}

form {
  display: flex;
  flex-direction: column;
}

.form-group {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
  gap: 10px;
}

.form-group-mobile {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
  width: 50%;
  gap: 10px;
}

input[type="text"],
input[type="tel"],
input[type="email"],
textarea {
  width: 45%;
  padding: 10px;
  font-size: 1rem;
  border: 1px solid #ddd;
  border-radius: 5px;
}

input[type="tel"],
input[type="email"],
textarea {
  width: 100%;
  resize: none;
}

input::placeholder,
textarea::placeholder {
  color: #888;
}

.request-demo-button {
  border: none;
  padding: 15px;
  border-radius: 5px;
  cursor: pointer;
  margin-top: 50px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 8px 16px;
  gap: 10px;
  
  width: 180px;
  height: 48px;
  
  background: #0C80EB;
  border-radius: 4px;
  font-family: 'Lato';
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 141.77%;
  color: #FFFFFF;
}

.request-demo-button:hover {
  background-color: #0056b3;
}

@media only screen and (max-width: 768px) {

  .demo-form-container {
    flex: 1;
    padding-left: 0;           /* Remove left padding to ensure elements start from the left */
    display: flex;
    flex-direction: column;     /* Stack items vertically */
    align-items: center;    /* Align items to the left */


  }
  
  .demo-heading {
    font-size: 2.0rem;
  }

}
/* Pricing Section */

.slick-slide-> div{
  margin: 0 10px;
}
.pricing-section {
  padding: 50px;
  text-align: center;
  background-color: #f9f9f9;
}

.pricing-title {
  margin-top: 40px;
  font-size: 1.4rem;
  color: #007bff;
  margin-bottom: 30px;
}

.pricing-subtitle {
  font-size: 2.1rem;
  font-weight: 800;
  color: #080707;
}

.pricing-subtitle-mobile {
  font-size: 2.1rem;
  font-weight: 800;
  color: #080707;
  margin-bottom: 35px;
}

/* Card Slider */
.card-slider {
  margin-top: 80px;
  display: flex;
  justify-content: center;
  gap: 30px;
}

.pricing-card {
  background-color: #fff;
  border-radius: 10px;
  padding: 20px;
  width: 290px;
  height: 490px;
  box-shadow: 0 6px 8px rgba(10, 2, 2, 0.199);
  cursor: pointer;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  border: 3px solid transparent;
}

.pricing-card.selected {
  transform: scale(1.1);
  border: 3px solid #007bff;
  background-color: #007bff;
  color: #fff;
}

.pricing-card-mobile {
  background-color: #fff;
  border-radius: 20px;
  padding: 20px;
  width: 290px;
  height: 480px;
  box-shadow: 0 6px 8px rgba(10, 2, 2, 0.199);
  cursor: pointer;
  border: 3px solid transparent;
}

.pricing-card-mobile.selected {
  background-color: #007bff;
}

.card-header {
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.card-header h3 {
  font-size: 1.7rem;
  color: #333;
  margin-bottom: 15px;
  font-family: 'Manrope';
  font-style: normal;
  font-weight: 700;
  font-size: 26px;
  line-height: 36px;
  color: #191D23;
  margin-top: 20px;
}

.card-header.selected h3{
  color: #FFFFFF;
}

.card-header img {
  width: 48px;
  height: 48px;
  margin-top: 0px;
}

.card-price {
  display: flex;
  align-items: center; 
}

.card-price-amount {
  font-family: 'Manrope';
  font-style: normal;
  font-weight: 600;
  font-size: 52px;
  line-height: 76px;
  color: #191D23;
  margin: 20px 0;
}

.card-price-amount.selected{
  color: #FFFFFF;
}

.card-price-per {
  margin: 20px 0;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #475569;
}

.card-price-per.selected{
  color: #FFFFFF;
}

.get-started-btn {
  background-color: #FFFFFF;
  color: #1D4ED8;
  padding: 10px 20px;
  border: 2px solid;
  border-radius: 25px;
  border-color: #1D4ED8;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.prizes-get-started-btn {
  background-color: #FFFFFF;
  color: #1D4ED8;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 6px 12px;
  gap: 4px;
  width: 242px;
  height: 44px;
  border: 1.5px solid #1D4ED8;
  border-radius: 4px;
  cursor: pointer;
  margin-bottom: 40px;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
/* identical to box height, or 150% */
  text-align: center;
}

.get-started-btn:hover {
  background-color: #0056b3;
}

/* Card Features */
.card-features {
  list-style: none;
  padding: 0;
  margin: 15px 0 0;
  color: #333;
  text-align: left; /* Ensure text aligns to the left */
}

.card-features li {
  font-size: 0.9rem;
  display: flex;
  align-items: flex-start; /* Align items to the top left */
  margin-bottom: 10px;
  font-family: 'Poppins', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #191D23;

}

.card-features.selected li{
  color: #FFFFFF;
}

.tick-mark {
  color: #28a745;
  margin-right: 8px;
  font-size: larger;
  font-weight: 900;
}

.tick-mark-icon {
  width: 32px;
  height: 32px;
  margin-right: 8px;
}

@media only screen and (max-width: 768px) {

  .pricing-title {
    font-size: 2.0rem;
  }

}

/* Contact Us Container Styles */
.contact-us-container {
  background-color: #FFFFFF; 
  padding: 70px 20px;
}

.contact-us-section {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  background-color: #E6FFFE; /* White background for better focus */
  border-radius: 0px;
  padding: 20px 20px;
  max-width: 1200px;
  margin: auto;
}

.left-section {
  flex: 1;
  padding: 20px;
  text-align: left;
  margin-top: 0;
}

.contact-heading{
  margin-bottom: 12px;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 700;
  font-size: 22px;
  line-height: 141.77%;
  color: #0C80EB;
  /* Contact us */
}
.connect-heading{
  margin-bottom: 12px;
  font-family: 'Kantumruy Pro';
  font-style: normal;
  font-weight: 700;
  font-size: 36px;
  line-height: 141.77%;
  color: #000000;
}
.contact-description{
  margin-bottom: 12px;
  font-family: 'Kantumruy Pro';
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 30px;
  color: #667085;
}

.contact-info{
 
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 8px;
  font-family: 'Kantumruy Pro';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 20px;

  color: #6F6C90;
}

.contact-info p {
  display: flex;
  align-items: center; /* Align icon and text vertically */
}

.email-sym-icon,.phone-sym-icon {
  width: 22px;
  height: 22px;
  margin-right: 8px;
}

.location-sym-icon {
  width: 20px;
  height: 25.7px;
  margin-right: 8px;
}

.right-section {
  flex: 1;
  padding: 10px;
  text-align: left;
  align-items: right;
  margin-left: 10px;
}

.form-group-conatct {
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
}

.form-group-conatct label{
  margin-bottom: 3px;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #344054;
}

.form-group-conatct input {
  width: 452px;
  height: 48px;
  padding: 10px;
  font-size: 1rem;
  border: 1px solid #ccc;
  border-radius: 4px;
  margin-bottom: 10px;
}

.form-group-conatct textarea {
  width: 452px;
  height: 128px;
  padding: 10px;
  font-size: 1rem;
  border: 1px solid #ccc;
  border-radius: 4px;
  height: 90px;
}

.submit-button {
  background-color: #007bff;
  color: white;
  padding: 10px 20px;
  font-size: 1rem;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  width: 452px;
  margin-top: 10px;
  height: 48px;
}

.submit-button:hover {
  background-color: #0056b3;
}

@media only screen and (max-width: 768px) {
  /* Contact Us Section */
  .contact-us-section {
    padding: 20px;
    background-color: #E6FFFE;
    text-align: center;

  .contact-heading{
    font-size: 2.1rem;
  }
}

.contact-heading{
  margin-bottom: 20px;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 700;
  font-size: 22px;
  line-height: 141.77%;
  color: #0C80EB;
}
.connect-heading{
  margin-bottom: 20px;
  font-family: 'Kantumruy Pro';
  font-style: normal;
  font-weight: 700;
  font-size: 36px;
  line-height: 141.77%;
  color: #000000;
}
.contact-description{
  margin-bottom: 20px;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 30px;
  color: #667085;
}

.contact-info{
 
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 24px;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 20px;

  color: #6F6C90;
}

/* Form Group for Mobile */
.form-group-mobile {
  margin-bottom: 20px;
}

.form-group-mobile input {
  width: 100%;
  padding: 10px;
  font-size: 1rem;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.form-group {
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
}

.form-group textarea {
  width: 100%;
  padding: 10px;
  font-size: 1rem;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.submit-button {
  background-color: #007bff;
  color: white;
  padding: 10px 20px;
  font-size: 1rem;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  width: 100%;
}

.submit-button:hover {
  background-color: #0056b3;
}

/* Mobile-specific styles */
  .contact-us-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .form-group-mobile, .form-group {
    width: 100%;
    text-align: left;
  }

}

/* Remaining CSS for headings, form fields */



/* Footer Container Styling */
.footer-container {
  display: flex;
  justify-content: space-between;
  padding: 40px 20px;
  background-color: #ffffff;
  border-top: 1px solid #e4e4e4;
  color: #343a40;
  font-size: 14px;
  margin-top: 40px;
}

.footer-left {
  flex: 1;
  padding: 0 20px;
  text-align: left;
  margin-right: 250px;
  margin-left: 90px;
}

.footer-middle {
  flex: 1;
  padding: 0 20px;
  text-align: left; 
  margin-right: 150px;
}

.footer-right {
  flex: 1;
  padding: 0 20px;
  text-align: left;
  /* margin-left: 650px; */
}

/* Footer Logo */
.footer-logo {
  max-width: 160px;
  margin-bottom: 20px;
}

.footer-logo-mobile {
  margin-top: 40px;
  max-width: 260px;
  margin-bottom: 20px;
}

/* Footer Social Icons */
.footer-description {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 30px;
  color: #6F6C90;
}

.footer-social-heading {
  margin-top: 20px;
  color: #000;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 22px;
  text-transform: capitalize;
  color: #170F49;
  margin-bottom: 16px;
}

.social-icons a {
  margin-right: 10px;
  font-size: 18px;
  color: #343a40;
  text-decoration: none;
}

.social-media-facebook-icon {
  width: 11px;
  height: 19px;
  margin-right: 18px;
}

.social-media-twitter-icon {
  width: 19px;
  height: 15px;
  margin-right: 18px;
}
.social-media-instagram-icon {
  width: 19px;
  height: 19px;
  margin-right: 18px;
}
.social-media-linkedIn-icon {
  width: 18px;
  height: 17.14px;
  margin-right: 18px;
}
.social-media-youtube-icon {
  width: 22px;
  height: 15px;
  margin-right: 18px;
}

.social-icons a:hover {
  color: #007bff;
}

/* Middle Section */
.footer-heading {
  margin-bottom: 25px;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 22px;
  color: #170F49;
}

.footer-links {
  list-style-type: none;
  padding: 0;
  font-size: 18px;
  color: #6c757d;
}

.footer-links li {
  margin-bottom: 10px;
  font-size: 18px;
  color: #6c757d;
}

.footer-links a {
  text-decoration: none;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 30px;
  color: #6F6C90;
}

.footer-links a:hover {
  color: #007bff;
  font-size: 18px;
}

/* Right Section */
.footer-right-heading {
  margin-bottom: 25px;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 22px;
  color: #170F49;
}
.footer-right p {
  margin: 10px 0;
  align-items: left;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 30px;
  color: #6F6C90;
}

/* Bottom Copyright Section */
.footer-bottom {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  background-color: #f8f9fc;
  font-size: 12px;
}

.footer-bottom-left {
  text-align: left;
  color: #6c757d;
  margin-left: 15vh;
}

.footer-bottom-left p {
  margin: 0;
}

.footer-bottom-right {
  text-align: right;
  margin-right: 25vh;
}

.footer-bottom-right a {
  color: #007bff;
  text-decoration: none;
}

.footer-bottom-right a:hover {
  text-decoration: underline;
}

.video-section {
  display: flex;
  justify-content: center; /* Center horizontally */
  align-items: start; /* Center vertically */
  height: 60vh; /* Full viewport height */
  width: 100%; /* Full width of the screen */
  background-color: #f8f9fa; /* Optional: Add a background color if needed */
}

.responsive-video {
  width: 50%; /* Make video take up the full width of the container */
  height: auto; /* Maintain the aspect ratio */
  max-width: 800px; /* Optional: Limit max width for larger screens */
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.3); 
}


@media only screen and (max-width: 768px) {
  .footer-left, .footer-middle, .footer-right {
    flex: 1;
    padding: 0 5px;
  }

  .footer-bottom {
    flex-direction: column; /* Stack items vertically */
  }

  .footer-bottom-left {
    margin-left: 0vh;
    text-align: center;
  }

  .footer-bottom-right {
    margin-top: 20px; /* Add spacing between sections */
    text-align: left; /* Align the link to the left */
    margin-right: 0vh;
    margin-bottom: 20px;
  }

  .video-section {
    display: flex;
    justify-content: center; /* Center horizontally */
    align-items: start; /* Center vertically */
    height: 30vh; /* Full viewport height */
    width: 100%; /* Full width of the screen */
    background-color: #f8f9fa; /* Optional: Add a background color if needed */
  }

  .responsive-video {
    width: 90%; /* Make video take up the full width of the container */
    height: auto; /* Maintain the aspect ratio */
    display: block; /* Prevent any inline element spacing issues */
  }
}